<template>
<div class="container" style="margin-top: 2.2%">
    <h1>Welcome to CyTox</h1>
    <canvas id="myChart" width="'100vh'" height="'110vh'"></canvas>
</div>
</template>

<script>
// import $ from "jquery";
import JQuery from 'jquery'
import Chart from 'chart.js/auto';
// import colorLib from '@kurkle/color';
import {
    DateTime
} from 'luxon';
window.$ = JQuery
export default {
    data() {
        return {
            datasets: [],
            labels: [],
        };
    },
    methods: {
        random_rgba() {
            var o = Math.round,
                r = Math.random,
                s = 255;
            return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
        },
        async DataSplunkData() {
            try {

                this.axios.get(this.$store.state.server_domain + "GetDataSplunkData", this.$store.state.config).then((res) => {
                    if (res.data) {
                        var datasets_backend = res.data.data.datasets;
                        var labels_backend = res.data.data.labels;
                        var curr_tools = Object.keys(datasets_backend);
                        for (var i = 0; i < curr_tools.length; i++) {
                            var curr_dataset = datasets_backend[curr_tools[i]];
                            var curr_tool = []
                            for (var j = 0; j < curr_dataset.length; j++) {
                                var tool = curr_dataset[j]
                                var date = new Date(tool.x);
                                date = DateTime.fromISO(date.toISOString()).toFormat('dd-MMM')
                                var value = parseInt(tool.y);
                                curr_tool.push({
                                    x: date,
                                    y: value
                                });
                            }

                            this.datasets.push({
                                label: curr_tools[i],
                                fill: false,
                                borderColor: this.random_rgba(),
                                tension: 0.1,
                                data: curr_tool,
                                pointStyle: 'circle',
                                pointRadius: 6,
                            })
                        }
                        for (var h = 0; h < labels_backend.length; h++) {
                            var date_label = new Date(labels_backend[h]);
                            date_label = DateTime.fromISO(date_label.toISOString()).toFormat('dd-MMM')
                            this.labels.push(date_label);
                        }
                        const data = {
                            labels: this.labels,
                            datasets: this.datasets
                        };
                        const config = {
                            type: 'line',
                            data: data,
                            options: {
                                plugins: {
                                    title: {
                                        text: 'Scanning Execution',
                                        display: true
                                    }
                                },
                                scales: {
                                    x: {
                                        display: true,
                                        title: {
                                            display: true,
                                            text: 'Date',
                                            color: '#000000',
                                            font: {
                                                family: 'Times',
                                                size: 20,
                                                style: 'normal',
                                                lineHeight: 1.2
                                            },
                                            padding: {
                                                top: 30,
                                                left: 0,
                                                right: 0,
                                                bottom: 0
                                            }
                                        }
                                    },
                                    y: {
                                        display: true,
                                        title: {
                                            display: true,
                                            text: 'Amount Of Queries',
                                            color: '#000000',
                                            font: {
                                                family: 'Times',
                                                size: 20,
                                                style: 'normal',
                                                lineHeight: 1.2
                                            },
                                            padding: {
                                                top: 30,
                                                left: 0,
                                                right: 0,
                                                bottom: 0
                                            }
                                        }
                                    }
                                }
                            },
                        };
                        const ctx = document.getElementById('myChart').getContext('2d');
                        // eslint-disable-next-line no-unused-vars
                        const myChart = new Chart(ctx, config);
                    } else {
                        this.$root.toast('Data Splunk Data', "Data Splunk Data GET Failed", "danger");
                    }
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        this.$root.toast("Data Splunk Data", `${err.response.data.error}`, "danger");
                    }
                });
            } catch (err) {
                this.$root.toast("Data Splunk Data", `${err.response.data.error}`, "danger");
            }

        },
    },
    beforeMount: async function () {},

    mounted: async function () {
        const users = await this.DataSplunkData();
        console.log(users);
        users.then(() => {
            const data = {
                labels: this.labels,
                datasets: this.datasets
            };
            let delayed;
            const config = {
                type: 'line',
                data: data,
                options: {
                    animation: {
                        onComplete: () => {
                            delayed = true;
                        },
                        delay: (context) => {
                            let delay = 0;
                            if (context.type === 'data' && context.mode === 'default' && !delayed) {
                                delay = context.dataIndex * 800 + context.datasetIndex * 200;
                            }
                            return delay;
                        },
                    },
                    plugins: {
                        title: {
                            text: 'Splunk Data',
                            display: true
                        }
                    },
                    tooltip: {
                        usePointStyle: true,
                    }
                },
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            // Luxon format string
                            tooltipFormat: 'DD T'
                        },
                        title: {
                            display: true,
                            text: 'Date'
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'value'
                        }
                    }
                },
            };
            const ctx = document.getElementById('myChart').getContext('2d');
            const myChart = new Chart(ctx, config);
            console.log(myChart)
        });

    },

};
</script>

<style>
.btm-margin {
    margin-bottom: 3%;
}

.tp-margin {
    margin-top: 10%;
}
</style>
